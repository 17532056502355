$input-bg: #f5f5f5;
$input-border-color: #C1C1C1;
$input-padding-y-sm: 0.9375rem;
$input-line-height-sm: 1.0625;
$input-height-sm: 47px;
$input-font-size-sm: 16px;

$brand-primary: #009BDA;
$body-color: #4D4D4D;

$pager-item-selected-bg: transparent;
$pager-item-selected-border: transparent;
$pager-number-bg: transparent;
$pager-number-hover-bg: transparent;
$pager-number-selected-bg: transparent;
$pager-number-border: transparent;
$pager-number-hover-border: transparent;
$pager-number-selected-border: transparent;
$pager-item-bg: transparent;
$pager-item-border: transparent;
$pager-item-hover-bg:transparent;
$pager-item-hover-border:transparent;
$pager-number-text: $body-color;
$pager-text: $body-color;
$pager-number-hover-text: $body-color;
$pager-number-selected-text: $body-color;
$pager-item-selected-text: $brand-primary;
$headings-font-weight: 600;

$grid-header-bg: #DCDCDC;

//Buttons
$btn-border-width:2px;
$btn-border-radius: 4px;
$btn-padding-y: 0.75rem;
$btn-padding-x: 1.25rem;
$btn-font-weight: 600;
$btn-font-size: 1rem;
$btn-color: #fff;


//Tables
$grid-header-bg: #DCDCDC;
$grid-header-text: #4D4D4D;


//Navbar
$navbar-padding-y: 8px;
$navbar-padding-x: 40px;

//Checkboxes
$checkbox-checked-bg: #009BDA;
$checkbox-checked-border: $brand-primary;
$checkbox-checked-text: $brand-primary;

$checkbox-focused-border: #979797;


//Select list
$selected-text: $brand-primary;
$selected-bg: transparent;
$selected-border: transparent;

//BS grid gutter
$grid-gutter-width: 15px;

//globals
$font-family-base: 'Roboto','Arial', sans-serif;

//cards
$card-border-color: #C1C1C1;
$card-border-radius: 4px;
$card-cap-bg: #4D4D4D;
$card-cap-color: #fff;
