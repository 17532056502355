@import "mixins/mixins";
@import "kendoVariables";

.k-datetimepicker {
    @include flexbox();
    height: auto;
}

.checkbox {
    svg {
        &[data-icon="square"] {
            path {
                fill: #150901;

                @include theme() {
                    fill: theme-get('checkbox-icon');
                }
            }
        }

        &[data-icon="check-square"] {
            path {
                fill: $brand-primary;
            }
        }
    }
}

label.col-form-label {
    padding: 0;
    font-size: 16px !important;
    margin-bottom: 5px;

    @include theme() {
        color: theme-get('input-color') !important;
    }
}

body {
    .form-group {
        position: relative;

        .input-group-append {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
        }

        .input-group-prepend {
            position: absolute;
            top: 24%;
            left: 0;
            z-index: 2;
        }

        .input-group-text {
            background: transparent;
            border: 0;
            color: inherit !important;
        }

        input {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .prependPadding {
            padding-left: 20px !important;
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            transition: all 0.3s ease-in-out;
            content: '';
            height: 2px;
            background: $brand-primary;
        }

        label.col-form-label {
            position: absolute;
            top: 50%;
            left: 8px;
            transition: all 0.3s ease-in-out;
            z-index: 2;
            padding: 0;
            transform: translateY(-50%);
            font-size: 12px !important;
            font-weight: 600;
            color: #8B8B8B;
            margin-bottom: 0;
            pointer-events: none;
        }

        &.has-value {
            &:after {
                width: 100%;
            }

            label.col-form-label {
                font-size: 12px !important;
                font-weight: 600;
                color: $brand-primary !important;
                top: 6px;
                transform: translateY(0);
            }
        }
    }

    .checkbox {
        height: 47px;

        &.form-control, &.form-control-plaintext {
            @include flexbox();
            @include align-items(center);
            width: auto;

            label {
                font-size: 16px !important;
                position: relative;
                top: 0;
                left: 0;
                margin-left: 8px;
                transform: none;

                @include theme() {
                    color: theme-get('checkbox-color') !important;
                }
            }
        }
    }

    .accordion-inner {
        .checkbox {
            label {
                color: #fff !important;
            }
        }
    }

    .k-icon.k-clear-value.k-i-close {
        color: $brand-primary;
    }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    @include theme() {
        background-color: theme-get('input-bg');
        color: theme-get('input-color') !important;
    }
}

.k-editor-content {
    @include theme() {
        background: theme-get('input-bg');
    }
    div {
        @include theme() {
            color: theme-get('text-color');
        }
    }
}

.k-pager-numbers {
    @include theme() {
        background: theme-get('bg-accent');
        color: theme-get('input-color');
    }

    .k-link {
        &.k-state-selected {
            text-decoration: underline;
            font-weight: bold;
            color: $brand-primary !important;
        }
    }
}
.k-pager-wrap {
    .k-link {
        color: $body-color;
    }

    .k-pager-sizes {
        select {
            width: auto;
            margin-right: 5px;
        }
    }
}

.k-upload-button {
    padding: 6px 8px;
    border-radius: 4px;
    background: $brand-primary;
    color: #fff;
    font-size: 14px;
    border: 0;
    margin-left: 70px;

    &:hover {
        background: $brand-primary;
    }
}

.k-upload {
    border: 0;

    .k-file {
        padding: 15px 11px;
        font-size: 16px;

        @include theme {
            background: theme-get('input-bg');
            color: theme-get('text-color');

            .k-button {
                padding: 0.3em;
                border-radius: 0.3em;
                border: 1px solid theme-get('active-icon-color');
                color: theme-get('active-icon-color');
                background-color: darken(theme-get('input-bg'), 2.5%);

                &:hover {
                    background-color: darken(theme-get('input-bg'), 7.5%);
                }
            }
        }
    }

    app-upload-single-file {
        width: 100%;

        .f-file-single {
            width: 100%;
            @include flexbox();
            @include justify-content(space-between);
        }
    }

    .k-dropzone {
        padding: 10px 11px;

        @include theme() {
            background: theme-get('bg-dropzone');
        }
    }

    .k-dropzone-hint {
        font-size: 14px;
        color: #8B8B8B !important;
    }

    .k-upload-files {
        border: 0;
    }
}

input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), select, textarea, span {
    &.form-control, &.k-dateinput-wrap input {
        border: 0;
        transition: all 0.3s;
        padding-top: 28px !important;
        border-bottom: 1px solid #cccccc !important;
        @include theme() {
            background-color: theme-get('input-bg');
            color: theme-get('input-color');
            border-color: theme-get('border-color');
        }
    }
}

.disabled, .is-read-only, app-control[ng-reflect-is-disabled="true"] {
    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), select, textarea, span {
        &.form-control, &.k-dateinput-wrap input {
            @include theme() {
                background-color: theme-get('input-bg-disabled') !important;
            }
        }
    }
}

input[readonly], textarea.disabled {
    @include theme() {
        background-color: theme-get('input-bg-disabled') !important;
    }
}

.k-picker-wrap {
    border: 0 !important;
    border-bottom: 1px solid transparent !important;
    @include theme() {
        border-color: theme-get('border-color') !important;
    }

    &:focus, &.k-state-focused {
        @include theme() {
            background: theme-get('input-focused') !important;
        }

        input {
            @include theme() {
                background: theme-get('input-focused') !important;
            }
        }
    }

    .k-select {
        @include theme() {
            background: theme-get('input-bg') !important;
        }
        color: $brand-primary !important;
        border: 0 !important;
        padding: 0 !important;

        .k-link-date {
            width: 100%;
        }

        .k-icon {
            font-size: 19px;
        }

        .k-link {
            border: 0;
        }

        &:hover {
            @include theme() {
                background: theme-get('input-bg') !important;
            }
            color: $brand-primary !important;
        }
    }
}

.k-calendar {
    .k-content.k-scrollable {
        padding-top: 5px;
    }

    .k-calendar-view {
        th {
            color: #8B8B8B;
            opacity: 1;
            font-size: 12px;
        }
    }
}

.k-datetime-container .k-datetime-calendar-wrap, .k-datetime-container .k-datetime-time-wrap {
    flex: 0 0 27em;
    height: 320px;
    overflow: hidden;

    @include theme() {
        background-color: theme-get('menu-bg');
        color: theme-get('menu-color');
    }
}

.k-date-tab, .k-time-tab {
    .k-time-highlight {
        background: $brand-primary;
        border-color: $brand-primary;
    }

    .k-datetime-buttongroup {
        padding: 15px;

        @include theme() {
            background-color: theme-get('menu-bg');
            color: theme-get('menu-color');
        }
    }

    .k-action-buttons {
        border-color: #C1C1C1;


        .k-button {
            width: 50%;
            padding: 15px;
            font-size: 14px;
            font-weight: 600;
            color: white;
            margin: 0;

            @include theme() {
                border-color: theme-get('border-color');
            }

            &.k-time-cancel {
                color: $body-color;
            }
        }
    }

    .k-calendar-header, .k-time-header {
        .k-button.k-bare, .k-title {
            color: $body-color;
            font-size: 14px;
            font-weight: 600;
            padding: 0 !important;

            &:hover {
                background: transparent;
            }
        }

        .k-time-now {
            color: $brand-primary !important;
            &:hover {
                background: transparent;
            }
        }
    }

    .k-link {
        border-radius: 2px;
    }

    tr[role="row"] {
        th[scope="col"] {
            padding: 0;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .k-today {
        font-size: 14px;
        font-weight: 600;
        color: $brand-primary !important;
        text-transform: capitalize;
    }

    .k-button-group {
        justify-content: center;
        .k-button {
            width: auto;
            flex-grow: 0;
            padding: 8px 24px;
            font-size: 16px;
            font-weight: 600;
            border-radius: 4px;
            background: transparent;
            border: 2px solid $brand-primary;
            box-shadow: none !important;

            @include theme() {
                color: theme-get('menu-color');
            }

            &.k-state-active {
                background: $brand-primary;
                color: #fff;
            }
        }
    }

    .k-time-list-wrapper {
        .k-title {
            margin: 0;
            font-weight: normal;
            color: #8B8B8B;
            font-size: 12px;
        }

        &.k-state-focused {
            &:before, &:after {
                display: none;
            }
        }
    }
}

.k-button:first-child {
    margin-right: 10px;
}

.k-popup {
    @include theme() {
        box-shadow: 0px 0px 30px rgba(theme-get('shadow'), 0.161);
    }
}

[class="k-popup"] {
    border: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0) !important;

    .k-chart-tooltip {
        border-radius: 4px;
        padding: 4px 6px 4px 6px;
        background-color: rgba(0, 0, 0, 0.9) !important;
        color: #fff;

        @include theme() {
            box-shadow: 0 2px 6px 0 rgba(theme-get('shadow'), 0.2);
        }
    }
}

app-control[ng-reflect-model="*"] {
    .row {
        background: red;
    }
}

.k-combobox .k-dropdown-wrap .k-input {
    font-size: 1rem;
}

.k-input {
    width: 100% !important;
}

.disabled:not(textarea) {
    @include theme() {
        background-color: theme-get('input-bg-disabled');
    }
}

.k-combobox, .k-numerictextbox {
    &:not(.is-read-only) {
        .k-dropdown-wrap, .k-select {
            height: 100%;

            @include theme() {
                background-color: theme-get('input-bg');
            }
        }
    }

    &.disabled, &.is-read-only {
        .k-dropdown-wrap, .k-select {
            @include theme() {
                background-color: theme-get('input-bg-disabled');
            }
        }
    }

    .k-dropdown-wrap, .k-select, .k-numeric-wrap {
        border: 0;
        height: 100%;

        input {
            background: transparent;
            padding-top: 28px !important;
            @include theme() {
                color: theme-get('input-color');
            }
        }
    }

    .k-select {
        border: 0 !important;
        background: transparent !important;
        color: #212529 !important;
    }

    .k-dropdown-wrap, .k-numeric-wrap {
        border-bottom: 1px solid $input-border-color;
        @include theme() {
            border-color: theme-get('border-color');
        }
    }

    .k-numeric-wrap {
        .k-link {
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);

            &.k-link-increase {
                @include align-items(flex-end);
                padding-bottom: 2px;

                &.k-state-active {
                    background: transparent;
                }

                .k-icon {
                    &:before {
                        content: '';
                        background: url("../icons/number-input-plus.svg") no-repeat center center;
                        background-size: contain;
                        width: 12px;
                        height: 8px;
                        display: block;
                    }
                }
            }

            &.k-link-decrease {
                @include flexbox();
                @include align-items(flex-start);
                padding-top: 2px;

                &.k-state-active {
                    background: transparent;
                }

                .k-icon {
                    &:before {
                        content: '';
                        background: url("../icons/number-input-minus.svg") no-repeat center center;
                        background-size: contain;
                        width: 12px;
                        height: 12px;
                        display: block;
                    }
                }
            }
        }
    }

    .k-dropdown-wrap {
        .k-select {
            .k-icon {
                &:before {
                    content: '';
                    background: url("../icons/number-input-minus.svg") no-repeat center center;
                    background-size: contain;
                    width: 12px;
                    height: 8px;
                    display: block;
                }
            }
        }
    }

    .k-dropdown-wrap, .k-numeric-wrap {
        //Global override k-icon controls to use flex, instead of abs pos
        .k-icon {
            position: relative !important;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            transform: none !important;
            width: auto !important;
            height: auto !important;
        }
    }
}

.k-numerictextbox .k-numeric-wrap {
    @include theme() {
        background: theme-get('input-bg');
        color: theme-get('input-color');
    }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    background-color: transparent;
}

body {
    //Select list
    .k-list {
        .k-item {
            border: 0;
        }
    }

    .k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected {
        color: $brand-primary;
        background-color: transparent;
        border: 0;
    }

    .k-checkbox {
        border-color: #979797;
        border-width: 2px;
        border-radius: 4px;
    }

    //Tables
    th.k-header {
        background-color: #4D4D4D;
        border-bottom: 4px solid $brand-primary;
        border-left: 0;
        border-right: 0;
    }

    .k-grid {
        tr:nth-child(1) > .k-group-cell.k-header:empty {
            border: 0 !important;
        }

        tr.k-group-footer {
            td.k-group-cell {
                border: 0 !important;
            }

            td:nth-of-type(2) {
                border-left: 0 !important;
            }
        }
    }

    .k-grid td {
        padding: 10px 12px;
    }

    .k-grid.compact {
        margin: 0;

        td, th {
            padding: 4px 6px;
        }

        tr.k-grouping-row td {
            padding: 2px 4px !important;
        }
    }

    .k-grid th {
        padding: 10px 0 10px 12px;
        vertical-align: middle !important;

        &.k-header {
            overflow: visible;
            white-space: pre-wrap;
            word-wrap: normal;
        }

        .k-cell-inner {
            margin: 0;
            max-width: 100%;

            .k-link {
                overflow: visible;
                white-space: pre-wrap;
                word-wrap: normal;
                padding: 0;

                .k-column-title {
                    max-width: 85%;
                    overflow: visible;
                    white-space: pre-wrap;
                    word-wrap: normal;
                }
            }
        }
    }

    .k-pager-refresh, .k-pager-numbers .k-link, .k-pager-nav {
        height: auto;
        padding: 0 5px !important;
        width: auto !important;
        min-width: unset;
    }
}

// Global table properties
.k-grid tbody td {
    text-overflow: clip;
    /* prevent the ellipsis behavior */
    overflow-wrap: break-word;

    button {
        border-radius: 4px;
    }
}

.k-grid, .k-grid-table {
    td {
        @include theme() {
            border-color: theme-get('border-color') !important;
            color: theme-get('input-color') !important;
        }
    }

    .k-toolbar {
        display: block;
        border: none;
        padding: 0.35rem 0.55rem;

        @include theme() {
            background-color: theme-get('input-bg-disabled');

            &[position="top"] {
                border-bottom: 1px solid theme-get('border-color');

                button {
                    margin: 0.2525rem !important;
                    padding: 0.125rem 0.2525rem !important;
                }
            }
            &[position="bottom"] {
                border-top: 1px solid theme-get('border-color');

                button {
                    margin-right: 0.55rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.k-grid.inline-editable {
    // Only way to somewhat affect this input's height
    input {
        max-height: 5px !important;
    }

    tr button {
        padding: 2px 4px;
        margin: 0;
        font-size: 12px;
    }

    tr td {
        &.k-detail-cell {
            padding-left: 0 !important;
        }

        &.k-hierarchy-cell:not(.ng-star-inserted) {
            border-left: 0 !important;
        }

        &.k-detail-cell, &.k-hierarchy-cell:not(.ng-star-inserted) {
            border-top: 1px !important;
            border-bottom: 1px !important;
            border-style: solid !important;

            @include theme() {
                border-color: theme-get('border-color') !important;
            }
        }
    }

    tr:last-child td {
        &.k-detail-cell, &.k-hierarchy-cell:not(.ng-star-inserted) {
            border-bottom: 0 !important;
        }
    }

    .k-grid-edit-row {
        td:not(.k-command-cell) {
            padding: 16px 6px 0 6px;
        }
    }
}

.k-grid.master-detail {
    tr, tr:hover {
        background: transparent;
    }

    tr, tr:hover {
        @include theme() {
            background-color: theme-get('table-stripe');

            &.k-alt {
                background-color: theme-get('table-alt-stripe');
            }
        }
    }

    tr td {
        &.k-detail-cell {
            padding-left: 0 !important;
        }

        &.k-hierarchy-cell {
            border-left: 0 !important;
        }

        &.k-detail-cell {
            border-top: 1px !important;
            border-bottom: 1px !important;
            border-style: solid !important;

            @include theme() {
                border-color: theme-get('border-color') !important;
            }
        }
    }

    tr:last-child td.k-detail-cell {
        border-bottom: 0 !important;
    }
}

.k-grid-header-wrap {
    border-right: 0;
}

.k-grid-header {
    border: 0;
    padding: 0;

    @include theme() {
        background-color: theme-get('input-bg-disabled') !important;
    }
}

//No other way to override BS .btn
.btn {
    color: $btn-color !important;
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        color: $body-color !important;

        &:hover {
            color: #fff !important;
        }
    }
}

.section-wrapper {
    background: #FBFBFB;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 10px;

    @include theme() {
        background: theme-get('bg-accent') !important;
        border-color: theme-get('border-color') !important;
    }
}

.card {
    @include theme() {
        background: theme-get('card-bg') !important;
        border-color: theme-get('card-border-color') !important;
    }
}

.k-dateinput .k-dateinput-wrap {
    @include theme() {
        background: theme-get('input-bg') !important;
        color: theme-get('input-color') !important;
        border-color: theme-get('border-color') !important;
    }
}

.k-editor {
    @include theme() {
        border-color: theme-get('border-color');
    }
}

.jumbotron {
    @include theme() {
        background: theme-get('jumbotron');
    }
}

.navbar-brand span, .user-dd-trigger, .user-dd-trigger .initials, .user-dd-trigger .name {
    @include theme() {
        color: theme-get('menu-color') !important;
    }

    &:hover {
        @include theme() {
            color: theme-get('menu-color');
        }
    }
}

.dropdown-menu {
    @include theme() {
        background-color: theme-get('main-bg') !important;
    }

    .dropdown-item {
        @include theme() {
            color: theme-get('menu-color');
        }

        &:hover {
            @include theme() {
                background-color: theme-get('bg-accent') !important;
            }
        }
    }
}

.k-grid {
    @include theme() {
        background-color: theme-get('menu-bg');
        border-color: theme-get('border-color');
    }
}

.k-grid-pager {
    @include theme() {
        background-color: theme-get('input-bg-disabled');
        border-color: theme-get('border-color');
        color: theme-get('table-text-color');
    }
}

.card-header {
    @include theme() {
        border-color: theme-get('border-color');
        background-color: theme-get('header-background');
        color: theme-get('text-color');
    }

    fa-icon > svg > path  {
        @include theme() {
            fill: theme-get('text-color');
        }
    }

    div.pointer > fa-icon > svg {
        float: right;
        margin: 0;
        font-size: 24.8px;
    }

    div > strong {
        @include theme() {
            color: theme-get('accordion-color') !important;
        }
    }

    h5 {
        display: inline;
        margin: 0;
    }

    .filter-indicator {
        height: 100%;
        float: right;
        font-size: 16px;
        font-weight: bold;

        @include theme() {
            color: theme-get('accordion-color') !important;
        }
    }

    height: auto;
    margin-top: -1px;
    border-radius: 4px 4px 0 0 !important;
}

.k-grid-header .k-header, .k-grouping-row, .k-grouping-row:hover, .k-group-cell, tr.k-group-footer td {
    @include theme() {
        background-color: theme-get('input-bg-disabled');
        color: theme-get('table-text-color') !important;
    }
}

.k-grouping-row {
    &:not(:first-of-type) td {
        border-top: 1px solid;
    }

    td {
        padding: 6px !important;
    }
}

.k-popup, .k-calendar, .k-time-list-wrapper {
    @include theme() {
        background-color: theme-get('menu-bg');
        color: theme-get('menu-color');
    }

    .k-title {
        @include theme() {
            background-color: theme-get('menu-bg');
        }
    }

    .k-content {
        @include theme() {
            color: theme-get('menu-color');
        }

        &:not(.k-time-container) {
            @include theme() {
                background-color: theme-get('menu-bg');
            }
        }
    }
}

.darkTheme {
    .k-time-list::before, .k-time-list::after {
        box-shadow: none;
    }
}

kendo-dialog {
    position: absolute;

    &.k-dialog-wrapper, > .k-overlay, > .k-dialog {
        z-index: 1050;
    }
}

.modal {
    position: absolute;
    z-index: 1051;
}

.modal-content, kendo-dialog div.k-dialog  {
    @include theme() {
        background-color: theme-get('menu-bg');
        color: theme-get('menu-color');

        .k-grid th, .k-grid td {
            @include theme() {
                color: theme-get('input-color');
            }
        }
    }

    .modal-header, .modal-footer, div.k-content {
        @include theme() {
            border-color: theme-get('border-color');
        }
    }

    .modal-body .k-textbox {
        padding-bottom: 15px;
    }
}

.card-body {
    padding: 10px;
    @include theme() {
        color: theme-get('text-color');
    }

    .k-content {
        padding: 10px 0 0 0;
    }
}

.linked-accordion {
    .card {
        margin-bottom: 20px;

        &:last-child {
            margin: 0;
        }
    }
}

.card-tabset {
    .card-header {
        padding: 0;
        background: transparent;
        border: 0;
        @include theme() {
            background-color: theme-get('tab-default');
        }

        .k-tabstrip-items {
            .k-item {
                border: 0;
                border-right: 1px solid transparent;
                font-size: 20px;
                font-weight: 600;
                margin: 0;

                @include theme() {
                    background-color: theme-get('tab-active');
                    color: theme-get('tab-active-color');
                }

                &:not(.k-state-active) {
                    @include theme() {
                        border-color: theme-get('border-color');
                        background-color: theme-get('tab-default');
                        color: theme-get('tab-default-color');
                    }
                }

                &:last-child {
                    border: 0 !important;
                }
            }
        }
    }
}

.icon-tabs {
    & > .k-content {
        background: transparent;
        border: 0;
        padding: 0;

        &:focus {
            outline: 0;
        }
    }

    .k-tabstrip-items {
        margin-bottom: 20px;
        border: 0;
        // @include flexbox();
        // @include flex-wrap(wrap);
    }

    fa-icon {
        font-size: 18px;
        @include theme() {
            color: theme-get('icon-color');
        }
    }

    .k-item {
        border: 0;
        background: transparent !important;

        &.k-state-active {
            .k-link {
                color: $brand-primary !important;
            }
        }

        &.hidden-tab {
            display: none;
        }
    }

    .k-link {
        font-size: 0.8rem;
        padding: 0 10px;
        font-weight: 600;
        height: 100%;

        @include theme() {
            color: theme-get('action-color') !important;
        }

        &:hover {
            color: $brand-primary !important;
        }

        & > div {
            height: 100%;
            @include flexbox();
            @include flex-direction(column);
            @include align-items(center);

            & > div {
                margin-top: auto;
            }
        }
    }
}

.k-autocomplete {
    @include theme() {
        background-color: theme-get('input-bg-color');
        color: theme-get('input-color');

        ::placeholder {
            color: theme-get('text-color') !important;
        }
    }
}

.table {
    @include theme() {
        background-color: theme-get('table-bg-color') !important;
        color: theme-get('table-text-color') !important;
    }

    .k-combobox {
        @include theme() {
            background-color: theme-get('table-input-color') !important;
            color: theme-get('table-text-color') !important;
        }
    }

    input {
        @include theme() {
            background-color: theme-get('table-input-color') !important;
            color: theme-get('table-text-color') !important;
        }
    }

    .k-autocomplete {
        @include theme() {
            background-color: theme-get('table-input-color') !important;
            color: theme-get('table-text-color') !important;
        }
    }

    .k-multiselect {
        @include theme() {
            background-color: theme-get('table-input-color') !important;
            color: theme-get('table-text-color') !important;
        }
    }

    .k-numerictextbox {
        @include theme() {
            background-color: theme-get('table-input-color') !important;
            color: theme-get('table-text-color') !important;
        }
    }
}

.k-multiselect {
    .k-input {
        padding-top: 38px !important;
    }
}

// Vessel Notification List/Conveyance Icons
.redIcon > svg > path {
    fill: red !important;
}

.greenIcon > svg > path {
    fill: green !important;
}

.blueIcon > svg > path {
    fill: #017aff !important;
}

.orangeIcon > svg > path {
    fill: orange !important;
}

.whiteIcon  {
    // filter: drop-shadow(1px 1px 0 rgba(255, 255, 255, 0.35));

    > svg > path {
        fill: white !important;
    }
}

.blackIcon  {
    filter: drop-shadow(1px 1px 0 rgba(255, 255, 255, 0.35));

    > svg > path {
        fill: black !important;
    }
}

.grayIcon  {
    > svg > path {
        fill: gray !important;
    }
}

.primaryColorIcon > svg > path {
    fill: #F36925 !important;
}

.k-multiselect .k-multiselect-wrap {
    @include theme() {
        background-color: theme-get('input-bg');
        color: theme-get('input-color');
        border-color: theme-get('border-color');
    }
}

.btn-text {
    @include theme() {
        color: theme-get('text-color');
    }
    padding-left: 4px;
}

kendo-chart > div > svg > g > path {
    @include theme() {
        fill: theme-get('tab-active') !important;
    }
}

text {
    @include theme() {
        fill: theme-get('chart-text');
    }
}

// Validation error messages draggable bar
.k-splitbar {
    padding: 8px 0 8px 0;

    @include theme() {
        color: theme-get('text-color');
        background: theme-get('border-bar-color');
        border-top: 1px solid theme-get('border-color');
    }
}

.action-bar {
    @include theme() {
        border-bottom: 19px solid theme-get('border-bar-color');

        .action-bar-group {
            border-right: 1px solid theme-get('border-color') !important;
        }
        &.minimized {
            border-bottom: 26px solid theme-get('border-bar-color');

            & > div > .group-label {
                height: 20px !important;
            }

            & > .action-bar-group {
                height: 26px !important;
                margin-bottom: -24px !important;
            }
        }
    }
}

svg:not(:root).svg-inline--fa {
    @include theme() {
        color: theme-get('text-color');
    }
}

.k-pager-wrap .k-link {
    @include theme() {
        color: theme-get('icon-color');
    }
}

.k-pager-wrap select.k-dropdown, .k-pager-wrap .k-dropdown .k-dropdown-wrap {
    @include theme() {
        color: theme-get('icon-color');
    }
}

.form-control-sm .k-multiselect-wrap li.k-button {
    margin-top: 25px;
}

app-vessel-notification-view-content, app-vessel-short-notification-view-content{
    h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5, strong {
        text-transform: uppercase;
    }
}

app-accordion-header > div {
    fa-icon {
        svg > path {
            @include theme() {
                fill: theme-get('text-color');
            }
        }
    }

    h5, strong {
        @include theme() {
            color: theme-get('accordion-color');
        }
    }

    li.nav-item > a.nav-link {
        @include theme() {
            color: theme-get('text-color');
        }
    }

    .k-combobox > span {
        border-bottom: 1px solid #cccccc !important;
    }

    .k-numerictextbox > span {
        border-bottom: 1px solid #cccccc !important;
    }

    .k-multiselect {
        border-bottom: 1px solid #cccccc !important;
    }
}

// Action bar hover
app-action-bar > div.action-bar > div.action-bar-group.dropdown > div > button:hover > fa-icon > svg > path {
    @include theme() {
        fill: theme-get('active-icon-color');
    }
}

app-action-bar > div.action-bar > div > div.small-buttons-container > div > button:hover > span > fa-icon > svg > path {
    @include theme() {
        fill: theme-get('active-icon-color');
    }
}

app-action-bar > div.action-bar > div.action-bar-group.dropdown > div > button:hover {
    @include theme() {
        background-color: theme-get('border-bar-color') !important;
    }
}

// Sidebar overwrites
.sidebar {
    // Groups
    a {
        @include theme() {
            color: theme-get('menu-color');
            background-color: theme-get('menu-bg');
        }

        &:hover {
            @include theme() {
                color: #fff;
                background-color: #009BDA;
            }

            .arrow-pointer {
                background: #30a0ca;
                color: #fff !important;
            }
        }
    }

    // Items inside groups
    ul > li > ul > li {
        &.active {
            a {
                @include theme() {
                    background-color: theme-get('tab-active-bg');
                    color: theme-get('menu-color');
                }
            }
        }

        &:hover:not(.active) {
            a {
                @include theme() {
                    background-color: lighten(theme-get('tab-active-bg'), 10%);
                    color: theme-get('menu-color');
                }
            }
        }
    }

    .arrow-pointer {
        @include theme() {
            background: theme-get('menu-pointer');
        }
    }
}

div > kendo-grid-list > div > div.k-grid-table-wrap > table > tbody > tr > td.k-touch-action-auto.text-center > div > div > span > span {
    @include theme() {
        color: theme-get('icon-text-color');
    }
}

.sticky-top {
    z-index: 59 !important;
}

kendo-tabstrip {
    ul[role="tablist"] > li > span > div > div.editable {
        @include theme() {
            background-color: $brand-primary !important;
            width: calc(100% + 20px);
        }
    }

    div.k-tabstrip-items-wrapper {
        z-index: 59;
        margin-bottom: 20px !important;
        @include theme() {
            background-color: theme-get('tabstrip-color') !important;
        }

        > ul {
            margin-bottom: 0px !important;
        }
    }

    &.stickyOpen > div.k-tabstrip-items-wrapper {
        position: sticky !important;
        top: 118px !important;
    }

    &.stickyClosed > div.k-tabstrip-items-wrapper {
        position: sticky !important;
        top: 30px !important;
    }

    &.stickyEmpty > div.k-tabstrip-items-wrapper {
        position: sticky !important;
        top: 5px !important;
    }

    div[role='tabpanel'] {
        @include theme() {
            background-color: theme-get('tabstrip-color');
            border-color: theme-get('tabstrip-color');
        }
    }

    ul[role="tablist"] > li {
        @include theme() {
            background-color: theme-get('tabstrip-color') !important;
        }
    }

    .k-item {
        @include theme() {
            color: theme-get('table-text-color') !important;
        }
    }

    ul > li > span {
        @include theme() {
            background-color: inherit;
            border-color: theme-get('tabstrip-color');

        }
        div > div {
            @include theme() {
            color: theme-get('table-text-color');
            }
        }
    }
    li[role='tab'] {
        @include theme() {
            border: 3px solid theme-get('tab-border') !important;
        }
        margin-right: 3px !important;
    }

    li[role='tab'].k-state-active {
        color: #009BDA !important;
        font-weight: 600;
        font-size: 15px;
        border-bottom: 3px solid #009BDA !important;
        transition: all 0.3s ease-in !important;
        @include theme() {
            border: 3px solid theme-get('tab-border');
            background-color: theme-get('tab-active-bg') !important;
            color: theme-get('active-icon-color') !important;
        }
        fa-icon {
            zoom: 1.2;
        }
    }
}

// The only way it works
#hazmat-info > div > div.contactInfo > div > app-control > div > label {
    color: red !important;
}

.k-slider-horizontal .k-slider-track, .k-slider-horizontal .k-slider-selection {
    height: 8px !important;
    left: 0;
    margin-top: -4px !important;
    top: 50% !important;
}

.k-slider .k-slider-selection {
    background-color: #009BDA !important;
}

.harbourOrAreaTitle, .collapseButton, .harbourOrAreaTotal, .noActiveVessels, .imoLink {
    @include theme() {
        color: theme-get('table-text-color') !important;
    }
}

.vtsCardHeader {
    @include theme() {
        border-bottom-color: theme-get('table-text-color') !important;
    }
}

.outButton, .activeVesselsOutButton {
    @include theme() {
        border-color: theme-get('tab-border') !important;
    }

    fa-icon {
        @include theme() {
            color: theme-get('tab-border') !important;
        }
    }
}

.k-chart {
    display: inline;
}

.k-drawer {
    @include theme() {
        background: theme-get('main-bg') !important;
    }

    .checkbox-label {
        @include theme() {
            color: theme-get('text-color') !important;
        }
    }
}

div.k-nodata {
    @include theme() {
        color: theme-get('text-color');
    }
}

app-vts-vessel-visit-modal {
    .event {
        @include theme() {
            background-color: theme-get('input-bg-disabled') !important;
        }

        &.active {
            @include theme() {
                background-color: theme-get('input-bg-color') !important;
                box-shadow: 0.5rem 0.5rem 0.5rem rgba(theme-get('event-button-shadow'), 0.4);
            }
        }
    }
}