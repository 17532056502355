@import "assets/scss/kendoVariables";

$themes: (
    darkTheme: (
        'input-bg': #4D4D4D,
        'input-bg-disabled': #313131,
        'input-focused': #7B7B7B,
        'input-color': #E1E1E1,
        'main-bg': #252525,
        'action-bg': #252525,
        'action-color': #fff,
        'headings-color': #E1E1E1,
        'bg-accent': #1E1E1E,
        'bg-dropzone': #1E1E1E,
        'border-color': #4D4D4D,
        'card-bg': #252525,
        'card-border-color': #4D4D4D,
        'accordion-color': #fff,
        'checkbox-color': #050505,
        'checkbox-icon': #fff,
        'menu-bg': #1E1E1E,
        'menu-color': #E1E1E1,
        'menu-pointer': #252525,
        'navbar-color': #8B8B8B,
        'jumbotron': #1E1E1E,
        'tab-active': #252525,
        'tab-default': #1E1E1E,
        'tab-default-color': #fff,
        'tab-active-color': #30a0ca,
        'border-bar-color': #1E1E1E,
        'group-label-color': #fff,
        'icon-color': #fff,
        'header-background': #4D4D4D,
        'header-icon-color': #fff,
        'input-bg-color': #4D4D4D,
        'table-bg-color': #3a3a3a,
        'table-text-color': #fff,
        'table-input-color': #4D4D4D,
        'text-color': #fff,
        'chart-background': #4D4D4D,
        'chart-text': #fff,
        'active-icon-color': #30a0ca,
        'nav-hover-color': #4D4D4D,
        'icon-text-color': #000,
        'tabstrip-color': #252525,
        'tab-active-bg' : #4D4D4D,
        'tab-border' : #4D4D4D,
        'button-bg': #8B8B8B,
        'table-stripe': #1E1E1E,
        'table-alt-stripe': #1A1A1A,
        'shadow': #000,
        'event-button-shadow': #888
    ),
    lightTheme: (
        'input-bg': $input-bg,
        'input-bg-disabled': #d4d4d4,
        'input-focused': #DCDCDC,
        'input-color': $body-color,
        'main-bg': #fff,
        'action-bg': #fff,
        'action-color': $body-color,
        'headings-color': $body-color,
        'bg-accent': #FBFBFB,
        'bg-dropzone': #F5F5F5,
        'jumbotron': #F5F5F5,
        'border-color': #C1C1C1,
        'card-bg': #fff,
        'card-border-color': $card-border-color,
        'accordion-color': #4D4D4D,
        'checkbox-color': $body-color,
        'checkbox-icon': #150901,
        'menu-bg': #fff,
        'menu-color': #4D4D4D,
        'menu-pointer': #EBEBEB,
        'navbar-color': $body-color,
        'tab-active': #FBFBFB,
        'tab-default': #EBEBEB,
        'tab-default-color': #7E7E7E,
        'tab-active-color': #4D4D4D,
        'border-bar-color': #f4f4f4,
        'group-label-color': #000,
        'icon-color': #4D4D4D,
        'header-background': #cccccc,
        'header-icon-color': #fff,
        'input-bg-color': #fff,
        'table-bg-color': #f4f4f4,
        'table-text-color': #000,
        'table-input-color': default,
        'text-color': default,
        'chart-background': #fff,
        'chart-text': default,
        'active-icon-color': #30a0ca,
        'nav-hover-color': #d8d8d8,
        'icon-text-color': #fff,
        'tabstrip-color': #fff,
        'tab-active-bg' : #cccccc,
        'tab-border' : #cccccc,
        'button-bg': #cccccc,
        'table-stripe': #fff,
        'table-alt-stripe': #F0F0F0,
        'shadow': #545454,
        'event-button-shadow': #000
    )
);

@mixin theme() {
    @each $theme, $map in $themes {
        $theme-map: $map !global;
        .#{$theme} & {
            @content;
        }
    }
    $theme-map: null !global;
}

@function theme-get($key) {
    @return map-get($theme-map, $key);
}
